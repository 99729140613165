import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {getAllGasStationAdmins} from "../../actions/admin";
import {addGasStation} from "../../actions/gasStation";
import {getAllGasStationManagers, getManagersByAdminId,} from "../../actions/manager";
import {getAllViewers, getViewersByAdminId} from "../../actions/viewer";
import AddressesComponent from "../../common/address/AddressesComponent";
import XIcon from "../../common/assets/x-icon.svg";
import Button from "../../common/button/button";
import {isAralDeClientId} from "../../common/client/clientHelper";
import InputController from "../../common/input/inputController";
import InputFieldWithLabel from "../../common/input/inputFieldWithLabel";
import TextareaWithLabel from "../../common/input/textareaWithLabel";
import LabelWithHR from "../../common/labels/labelWithHR";
import {aralFuelTypes, ROLES} from "../../consts";
import {newModalStyle} from "../../reusable/styles";
import {formatCurrencyCode} from "../../utils/formatter";
import {mapUser} from "../../utils/mapUser";
import Style from "./addGasStationModal.module.css";

const aralFuelTypesTransformed = Object.values(aralFuelTypes);
const AddGasStationModal = (props) => {
  const {handleSubmit, register, watch, control, setValue, getValues}
      = useForm({
    defaultValues: {
      intentAdditionalFee: 50,
      intentPaymentPeriod: 14,
      intentReminderFee: 20,
      intentReminderPaymentPeriod: 7,
      promissoryNotePaymentFee: 10,
      promissoryNoteGracePeriod: 3,
      promissoryNoteAdditionalFee: 50,
      promissoryNotePaymentPeriod: 14,
      promissoryNoteReminderFee: 20,
      promissoryNoteReminderPaymentPeriod: 7,
      debtNoteAdditionalFee: 50,
      debtNotePaymentPeriod: 14,
      debtNoteReminderFee: 20,
      debtNoteReminderPaymentPeriod: 7,
      pumpSwappedAdditionalFee: 50,
      pumpSwappedPaymentPeriod: 14,
      pumpSwappedReminderFee: 20,
      pumpSwappedReminderPaymentPeriod: 7,
      otherReasonAdditionalFee: 50,
      otherReasonPaymentPeriod: 14,
      otherReasonReminderFee: 20,
      otherReasonReminderPaymentPeriod: 7,
    }
  });
  const { t } = useTranslation();
  const auth = useAuth();
  const loggedUser = mapUser(auth);
  const dispatch = useDispatch();

  const { admins } = useSelector((state) => state.admin);
  const { managers } = useSelector((state) => state.manager);
  const { viewers } = useSelector((state) => state.viewer);
  const { clients } = useSelector((state) => state.client);

  const [managersIds, setManagersIds] = useState([]);
  const [viewersIds, setViewersIds] = useState([]);
  const [clientId, setClientId] = useState("");
  const [powerOfAttorneyName, setPowerOfAttorneyName] = useState("");
  const [powerOfAttorney, setPowerOfAttorney] = useState(null);
  const [documentation, setDocumentation] = useState(null);
  const [documentationName, setDocumentationName] = useState("");
  const [managersComboBoxNames, setManagersComboBoxNames] = useState([]);
  const [viewersComboBoxNames, setViewersComboBoxNames] = useState([]);
  const [contractDate, setContractDate] = useState(null);
  const [useClientDocs, setUseClientDocs] = useState(false);
  const [counter, setCounter] = useState(0);
  const [viewerCounter, setViewerCounter] = useState(0);

  const isLoggedUserSysadmin = loggedUser?.roles?.includes(ROLES.SYSADMIN);

  const [keycloakAdminId, setKeycloakAdminId] = useState("0");

  const name = watch("name");

  const [categoryPaymentDetails, setCategoryPaymentDetails]
      = useState({

  });
  const customer = watch("customer");

  const [address, setAddress] = useState(null);
  const internationalFee = watch("internationalFee");
  const clientName = watch("clientName");
  const clientNumber = watch("clientNumber");
  const profitCenter = watch("profitCenter");
  const costCenter = watch("costCenter");
  const iban = watch("iban");
  const bic = watch("bic");
  const payee = watch("payee");
  const financialInstitution = watch("financialInstitution");
  const salesArea = watch("salesArea");
  const others = watch("others");
  const notedBy = watch("notedBy");
  const injuredPerson = watch("injuredPerson");
  const representedBy = watch("representedBy");
  const relationToProperty = watch("relationToProperty");
  const additionalInfo = watch("additionalInfo");
  const countryWatch = watch("country", "0");
  const externalReferenceId = watch("externalReferenceId");

  const [fuelTypesSelected, setFuelTypesSelected] = useState(
    aralFuelTypesTransformed.reduce((acc, element) => ({ ...acc, [element]: false }), {})
  );

  const resetSelectedFuelTypes = () => {
    setFuelTypesSelected(
      aralFuelTypesTransformed.reduce((acc, name) => {
        acc[name] = false;
        return acc;
      }, {})
    );
  };
  const handleFuelTypesChange = (name, value) => {
    setFuelTypesSelected((prevStates) => {
      const newState = { ...prevStates, [name]: value };
      return newState;
    });
  };

  const isAdminSelected = () => {
    return isLoggedUserSysadmin ? keycloakAdminId === "0" : false;
  };

  const isSubmitDisabled =
    !address || !name || isAdminSelected() ||
      managersIds?.includes("0") || viewersIds?.includes("0");

  useEffect(() => {
    if (isLoggedUserSysadmin) {
      dispatch(getAllGasStationAdmins());
      dispatch(getAllGasStationManagers());
      dispatch(getAllViewers());
    } else {
      dispatch(getManagersByAdminId());
      dispatch(getViewersByAdminId());
    }
  }, [dispatch, isLoggedUserSysadmin]);

  const setGasStationAdmin = (id) => {
    setKeycloakAdminId(id);
  };

  const renderManagers = () => {
    const getListOfManagers = (managers, ordinalNumber) => {
      if (ordinalNumber === 1) return managers;
      return managers.filter(
        (m) => !managersIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
      <div>
        {managersIds.map((manager, index) => {
          const ordinalNumber = index + 1;

          return (
            <div key={index} className={Style.manager}>
              <div className={Style.manager__select}>
                <Controller
                    name={`parkingZoneManager_${managersComboBoxNames[index]}`}
                    control={control}
                    render={() => (
                        <Autocomplete
                            className={Style.textStyleSelectEditModal}
                            id={`parkingZoneManager_${managersComboBoxNames[index]}`}
                            onChange={(e,value)  => {
                              if(value) {
                                managersIds[index] = value.id;
                                setManagersIds([...managersIds]);
                              }
                            }}
                            disableClearable
                            value = {managers.find(x=>x.id===manager) || null}
                            renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                            options={getListOfManagers(managers, ordinalNumber).filter((m) => m.id !== keycloakAdminId)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                        />
                    )}
                />
              </div>
              <div className={Style.manager__removeBtn}>
                <img
                  alt=""
                  src={XIcon}
                  height="25px"
                  width="25px"
                  onClick={(e) => {
                    setManagersIds([
                      ...managersIds.filter((m, i) => i !== index),
                    ]);
                    managersComboBoxNames.splice(index, 1);
                    setManagersComboBoxNames([...managersComboBoxNames]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const renderViewers = () => {
    const getListOfViewers = (viewers, ordinalNumber) => {
      if (ordinalNumber === 1) return viewers;
      return viewers.filter(
          (m) => !viewersIds.slice(0, ordinalNumber - 1).includes(m.id)
      );
    };

    return (
        <div>
          {viewersIds.map((viewer, index) => {
            const ordinalNumber = index + 1;

            return (
                <div key={index} className={Style.manager}>
                  <div className={Style.manager__select}>
                    <Controller
                        name={`parkingZoneViewer_${viewersComboBoxNames[index]}`}
                        control={control}
                        render={() => (
                            <Autocomplete
                                className={Style.textStyleSelectEditModal}
                                id={`parkingZoneManager_${viewersComboBoxNames[index]}`}
                                onChange={(e,value)  => {
                                  if(value) {
                                    viewersIds[index] = value.id;
                                    setViewersIds([...viewersIds]);
                                  }
                                }}
                                disableClearable
                                value = {viewers.find(x=>x.id===viewer) || null}
                                renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                                options={getListOfViewers(viewers, ordinalNumber).filter((m) => m.id !== keycloakAdminId)}
                                getOptionSelected={(option, value) => option.id === value.id}
                                getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName?"| "+x.companyName:""}`}
                            />
                        )}
                    />
                  </div>
                  <div className={Style.manager__removeBtn}>
                    <img
                        alt=""
                        src={XIcon}
                        height="25px"
                        width="25px"
                        onClick={(e) => {
                          setViewersIds([
                            ...viewersIds.filter((m, i) => i !== index),
                          ]);
                          viewersComboBoxNames.splice(index, 1);
                          setViewersComboBoxNames([...viewersComboBoxNames]);
                        }}
                    />
                  </div>
                </div>
            );
          })}
        </div>
    );
  };

  const documentUpload = (e, files, setFiles, filesNames, setFilesNames, isSingleFile) => {
    if (e.target.files.length !== 0) {
        let fileList = files === null ? [] : files;
        let names = filesNames;
        for (let i = 0; i < e.target.files.length; i++) {
          if (isSingleFile && i === 0) {
            fileList = [];
            fileList.push(e.target.files[i])
            names = e.target.files[i].name;
          } else {
            fileList.push(e.target.files[i]);
            names = names === "" ? e.target.files[i].name : names.concat("|", e.target.files[i].name);
          }
        }
        setFiles(fileList);
        setFilesNames(names);
      }
  };

  const saveGasStation = async (data) => {
    const formData = new FormData();

    for (let i = 0; i < powerOfAttorney?.length; i++) {
      formData.append("powerOfAttorney", powerOfAttorney[i]);
    }
    formData.append("powerOfAttorneyName", powerOfAttorneyName);
    for (let i = 0; i < documentation?.length; i++) {
      formData.append("documentation", documentation[i]);
    }
    formData.append("documentationName", documentationName);



    const {
      intentAdditionalFee,
      intentPaymentPeriod,
      intentReminderFee,
      intentReminderPaymentPeriod,
      promissoryNotePaymentFee,
      promissoryNoteGracePeriod,
      promissoryNoteAdditionalFee,
      promissoryNotePaymentPeriod,
      promissoryNoteReminderFee,
      promissoryNoteReminderPaymentPeriod,
      debtNoteAdditionalFee,
      debtNotePaymentPeriod,
      debtNoteReminderFee,
      debtNoteReminderPaymentPeriod,
      pumpSwappedAdditionalFee,
      pumpSwappedPaymentPeriod,
      pumpSwappedReminderFee,
      pumpSwappedReminderPaymentPeriod,
      otherReasonAdditionalFee,
      otherReasonPaymentPeriod,
      otherReasonReminderFee,
      otherReasonReminderPaymentPeriod
    } = data;

    const categoryPaymentDetails = {
      intentAdditionalFee,
      intentPaymentPeriod,
      intentReminderFee,
      intentReminderPaymentPeriod,
      promissoryNotePaymentFee,
      promissoryNoteGracePeriod,
      promissoryNoteAdditionalFee,
      promissoryNotePaymentPeriod,
      promissoryNoteReminderFee,
      promissoryNoteReminderPaymentPeriod,
      debtNoteAdditionalFee,
      debtNotePaymentPeriod,
      debtNoteReminderFee,
      debtNoteReminderPaymentPeriod,
      pumpSwappedAdditionalFee,
      pumpSwappedPaymentPeriod,
      pumpSwappedReminderFee,
      pumpSwappedReminderPaymentPeriod,
      otherReasonAdditionalFee,
      otherReasonPaymentPeriod,
      otherReasonReminderFee,
      otherReasonReminderPaymentPeriod,
    };

    const newGasStation = {
      name,
      categoryPaymentDetails,
      customer,
      address,
      adminKeycloakId: isLoggedUserSysadmin ? keycloakAdminId : loggedUser.id,
      managersIds,
      viewersIds,
      clientId,
      useClientDocs,
      internationalFee,
      clientName,
      clientNumber,
      profitCenter,
      costCenter,
      iban,
      bic,
      payee,
      financialInstitution,
      contractDate,
      salesArea,
      others,
      notedBy,
      injuredPerson,
      representedBy,
      relationToProperty,
      additionalInfo,
      externalReferenceId
    };

    for (let i = 0; i < aralFuelTypesTransformed.length; i++) {
      if(fuelTypesSelected[aralFuelTypesTransformed[i]]) {
        newGasStation.aralFuelTypes =  (newGasStation.aralFuelTypes? newGasStation.aralFuelTypes : "")  + aralFuelTypesTransformed[i] + ',';
      }
    }
    if (newGasStation.aralFuelTypes!=null && newGasStation.aralFuelTypes.charAt( newGasStation.aralFuelTypes.length-1)===',') {
      newGasStation.aralFuelTypes = newGasStation.aralFuelTypes.substring(0, newGasStation.aralFuelTypes.length-1);
    }
    formData.append(
      "gasStationZone",
      new Blob([JSON.stringify(newGasStation)], {
        type: "application/json",
      })
    );

    dispatch(addGasStation(formData, props.onClose));
  };

  const removeDocument = (
    files,
    setFiles,
    filesNames,
    setFilesNames,
    index
  ) => {
    setFiles([...files?.filter((f, i) => i !== index)]);
    const namesArray = filesNames?.split("|");
    namesArray.splice(index, 1);
    setFilesNames(namesArray?.join("|"));
  };

  const showDocumentsNames = (files, setFiles, filesNames, setFilesNames) => {
    return filesNames?.split("|")?.map((n, index) => (
      <div key={index} className={Style.uploadedFile}>
        <div className={Style.uploadedFile__name}>{n}</div>
        <div className={Style.uploadedFile__line} />
        <div className={Style.uploadedFile__removeBtn}>
          <img
            alt=""
            src={XIcon}
            height="25px"
            width="25px"
            onClick={() => {
              removeDocument(files, setFiles, filesNames, setFilesNames, index);
            }}
          />
        </div>
      </div>
    ));
  };

  const currencyDisplay = formatCurrencyCode(countryWatch)

  const internationalFeeLabel = t("GasStations.internationalFee") + " " + currencyDisplay;
  const additionalFeeLabel = t("GasStations.additionalFee") + " " + currencyDisplay;
  const reminderFeeLabel = t("GasStations.reminderFee")  + " " + currencyDisplay;
  const paymentFeeLabel = t("GasStations.paymentFee") + " " + currencyDisplay;
  

  return (
    <Modal
        open={props.open}
        closeOnEsc
        onClose={props.onClose}
        center
        styles={newModalStyle({width: "70%", maxWidth: "900px"})}
    >
      <div className="modal__headerLine"/>
      <div className={cn("modal__title", Style.modal__title)}>
        {props.title}
      </div>

      <form onSubmit={handleSubmit((data) => saveGasStation(data))}>
        <div className={Style.formContainer}>
          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.basicInfo")}
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <div className={Style.gsNameContainer}>
                <InputFieldWithLabel
                    label={t("GasStations.gsName")}
                    name="name"
                    registerName="name"
                    register={register}
                    required={true}
                />
              </div>

              {/*put client name to this row if there is no client entity*/}
              {!isAralDeClientId(clientId) &&
                  <div className={Style.gsPaymentPeriodContainer}>
                    <InputFieldWithLabel
                        label={t("Clients.Client")}
                        name="customer"
                        registerName="customer"
                        register={register}
                        type="text"
                        disabled={clientId}
                    />
                  </div>}
            </div>

            {isAralDeClientId(clientId) &&
                <div className={Style.gsPaymentInfoContainer}>
                  <div className={cn(Style.gsNameContainer)}>
                    <InputFieldWithLabel
                        label={t("EditGasStationModal.externalReferenceId", { customer })}
                        name="externalReferenceId"
                        type="number"
                        registerName="externalReferenceId"
                        register={register}
                    />
                  </div>

                  <div className={cn(Style.gsPaymentPeriodContainer)}>
                    <InputFieldWithLabel
                        label={t("Clients.Client")}
                        name="customer"
                        type="text"
                        registerName="customer"
                        register={register}
                        disabled
                    />
                  </div>
                </div>}


            <div className={Style.gsPaymentInfoContainer}>
              <div className={Style.gsNameContainer}>
                <InputFieldWithLabel
                    className={"container--margineRight"}
                    label={t("GasStationDetailsModal.clientNumber")}
                    name="clientNumber"
                    registerName="clientNumber"
                    register={register}
                    type="text"
                />
              </div>
              <div className={Style.gsPaymentPeriodContainer}>
                <InputFieldWithLabel
                    label={t("GasStationDetailsModal.clientName")}
                    name="clientName"
                    registerName="clientName"
                    register={register}
                    type="text"
                />
              </div>
            </div>
            <div className={Style.gsPaymentInfoContainer}>
              <div className={Style.gsNameContainer}>
                <InputFieldWithLabel
                    className={"container--margineRight"}
                    label={t("GasStationDetailsModal.profitCenter")}
                    name="profitCenter"
                    registerName="profitCenter"
                    register={register}
                    type="text"
                />
              </div>
              <div className={Style.gsPaymentPeriodContainer}>
                <InputFieldWithLabel
                    label={t("GasStationDetailsModal.costCenter")}
                    name="costCenter"
                    registerName="costCenter"
                    register={register}
                    type="text"
                />
              </div>
            </div>
            <div className={Style.gsPaymentInfoContainer}>
              <div className={Style.gsInternationalFeeContainer}>
                <InputFieldWithLabel
                    className={"container--margineRight"}
                    label={internationalFeeLabel}
                    name="internationalFee"
                    registerName="internationalFee"
                    register={register}
                    type="number"
                    min={0}
                />
              </div>
            </div>
          </div>

          <div className={Style.section} >
            <div
                className={cn(
                    Style.section__title,
                    Style["section__title--admin"]
                )}
            >
              {t("Clients.Client")}
            </div>

            {isLoggedUserSysadmin ? (
                <div>
                  <div className={Style.manager}>
                    <div className={Style.manager__select}>
                      <Controller
                          name="clientId"
                          control={control}
                          render={() => (
                              <Autocomplete
                                  className={Style.textStyleSelectEditModal}
                                  id={"clientId"}
                                  onChange={(e, value) => {
                                    if (value) {
                                      setClientId(value.id);
                                      setValue("customer", `${value.name}`);
                                    }
                                  }}
                                  disableClearable
                                  value={clients.find(x => x.id === clientId) || null}
                                  renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                                  options={clients}
                                  getOptionSelected={(option, value) => option.id === value.id}
                                  getOptionLabel={(x) => `${x.name} | ${x.representativeName}`}
                              />
                          )}
                      />
                    </div>
                    <div className={Style.manager__removeBtn} onClick={() => {
                      setClientId(null);
                      setUseClientDocs(false);
                      setValue("customer", "");
                    }}>
                      <img
                          alt=""
                          src={XIcon}
                          height="25px"
                          width="25px"
                      />
                    </div>
                  </div>
                  <div className="objectionEnabledCheckbox">
                    <input
                        type="checkBox"
                        className="checkboxObjectionEnabled"
                        disabled={!clientId}
                        onChange={() => {
                          setUseClientDocs(
                              !useClientDocs
                          );
                        }}
                        checked={useClientDocs}
                    />
                    <div
                        className="objectionEnabled"
                        style={{fontSize: 14}}
                    >
                      {t("Clients.UseClientDocs")}
                    </div>
                  </div>
                </div>
            ) : (
                <div className={Style.text}>
                  {clients.find(client => client.id === clientId)?.name || ""}
                </div>
            )}
          </div>

          {isAralDeClientId(clientId) && (
            <div className={Style.section}>
              <div className={Style.section__title}>
                {t("GasStations.fuelTypesTitle")}
              </div>
              <div  className="fuel-checkbox-container">
              {aralFuelTypesTransformed.map((value, index) => (
                  <div className="objectionEnabledCheckbox">
                    <input
                        key={index}
                        type="checkBox"
                        className="checkboxObjectionEnabled"
                        disabled={!clientId}
                        onChange={(e) => handleFuelTypesChange(value, e.target.checked)}
                        checked={fuelTypesSelected[value]}
                    />
                    <div
                        className="objectionEnabled"
                        style={{fontSize: 14}}
                    >
                      {value}
                    </div>
                  </div>
              ))}
              </div>
            </div>
          )}
          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.inetntPaymentInfoTitle")}
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <div className={cn(
                  Style.container,
                  Style["container--margineRight"],
              )}>
                <InputFieldWithLabel
                  className={Style.textStyleInputEditModal}
                  label={additionalFeeLabel}
                  name={"intentAdditionalFee"}
                  registerName={"intentAdditionalFee"}
                  register={register}
                  type="number"
                  min={0}
                />
              </div>

              <div className={Style.container}>
                <InputFieldWithLabel
                  className={Style.textStyleInputEditModal}
                  label={t("GasStations.paymentPeriod")}
                  name={"intentPaymentPeriod"}
                  registerName={"intentPaymentPeriod"}
                  register={register}
                  type={"number"}
                  min={0}
                />
              </div>
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <div className={cn(
                  Style.container,
                  Style["container--margineRight"]
              )}>
                <InputFieldWithLabel
                  className={Style.textStyleInputEditModal}
                  label={reminderFeeLabel}
                  name={"intentReminderFee"}
                  registerName={"intentReminderFee"}
                  register={register}
                  type={"number"}
                  min={0}
              /></div>

              <div className={Style.container}>
                <InputFieldWithLabel
                  className={Style.textStyleInputEditModal}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"intentReminderPaymentPeriod"}
                  registerName={"intentReminderPaymentPeriod"}
                  register={register}
                  type={"number"}
                  min={0}
                />
              </div>
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.promissoryNotePaymentInfoTitle")}
            </div>

            <div className={Style.gsPaymentInfoContainer}>
                <InputController
                    containerStyles={cn(
                        Style.container,
                        Style["container--margineRight"]
                    )}
                    label={paymentFeeLabel}
                    name={"promissoryNotePaymentFee"}
                    control={control}
                    controllerClassName={Style.textStyleInputEditModal}
                    type={"number"}
                    min={0}
                    onChange={(e) => {
                      setCategoryPaymentDetails({
                        ...categoryPaymentDetails,
                        promissoryNotePaymentFee: e.target.value,
                      });
                    }}
                />

                <InputController
                   containerStyles={Style.container}
                    label={t("GasStations.gracePeriod")}
                    name={"promissoryNoteGracePeriod"}
                    control={control}
                    controllerClassName={Style.textStyleInputEditModal}
                    type={"number"}
                    min={0}
                    onChange={(e) => {
                      setCategoryPaymentDetails({
                        ...categoryPaymentDetails,
                        promissoryNoteGracePeriod: e.target.value,
                      });
                    }}
                />
            </div>
            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={additionalFeeLabel}
                  name={"promissoryNoteAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNoteAdditionalFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"promissoryNotePaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNotePaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={reminderFeeLabel}
                  name={"promissoryNoteReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNoteReminderFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"promissoryNoteReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      promissoryNoteReminderPaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.debtNotePaymentInfoTitle")}
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={additionalFeeLabel}
                  name={"debtNoteAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNoteAdditionalFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"debtNotePaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNotePaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={reminderFeeLabel}
                  name={"debtNoteReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNoteReminderFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"debtNoteReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      debtNoteReminderPaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.pumpSwappedPaymentInfoTitle")}
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={additionalFeeLabel}
                  name={"pumpSwappedAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedAdditionalFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"pumpSwappedPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedPaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={reminderFeeLabel}
                  name={"pumpSwappedReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedReminderFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"pumpSwappedReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      pumpSwappedReminderPaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("GasStations.otherReasonPaymentInfoTitle")}
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={additionalFeeLabel}
                  name={"otherReasonAdditionalFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonAdditionalFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.paymentPeriod")}
                  name={"otherReasonPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonPaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>

            <div className={Style.gsPaymentInfoContainer}>
              <InputController
                  containerStyles={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
                  label={reminderFeeLabel}
                  name={"otherReasonReminderFee"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonReminderFee: e.target.value,
                    });
                  }}
              />

              <InputController
                  containerStyles={Style.container}
                  label={t("GasStations.reminderPaymentPeriod")}
                  name={"otherReasonReminderPaymentPeriod"}
                  control={control}
                  controllerClassName={Style.textStyleInputEditModal}
                  type={"number"}
                  min={0}
                  onChange={(e) => {
                    setCategoryPaymentDetails({
                      ...categoryPaymentDetails,
                      otherReasonReminderPaymentPeriod: e.target.value,
                    });
                  }}
              />
            </div>
          </div>

          <div className={Style.section}>
            <AddressesComponent
                onAddressAdded={(newAddress) => {
                  setAddress(newAddress)
                }}
                watch={watch}
                register={register}
            />
          </div>

          <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "30px",
                marginLeft: "30px",
                marginTop: "20px",
                justifyContent: "space-between",
              }}
          >
            <div style={{zIndex: 3, flex: 1}}>
              <LabelWithHR label={t("ParkingZones.contractDate")}/>
              <Controller
                  name="contractDate"
                  control={control}
                  render={() => (
                      <DatePicker
                          selected={contractDate}
                          dateFormat="dd.MM.yyyy"
                          popperPlacement="top"
                          className={Style.textStyleInputEditModal}
                          locale="de"
                          onChange={(date) => setContractDate(date)}
                      />
                  )}
              />
            </div>
            <div style={{flex: 1, marginLeft: "40px"}}>
              <InputFieldWithLabel
                  label={t("ParkingZones.salesArea")}
                  name="salesArea"
                  registerName="salesArea"
                  register={register}
              />
            </div>
          </div>
          <div className={cn(Style.section, Style["section--payment"])}>
            <div className={Style.section__title}>
              {t("ParkingZones.paymentInfo")}
            </div>
            <div className={Style.paymentSection__rowContainer}>
              <div
                  className={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
              >
                <InputFieldWithLabel
                    label="IBAN"
                    name="iban"
                    registerName="iban"
                    register={register}
                />
              </div>
              <div
                  className={cn(Style.container, Style["container"])}
              >
                <InputFieldWithLabel
                    label="BIC"
                    name="bic"
                    registerName="bic"
                    register={register}
                />
              </div>
            </div>
            <div className={Style.paymentSection__rowContainer}>
              <div
                  className={cn(
                      Style.container,
                      Style["container--margineRight"]
                  )}
              >
                <InputFieldWithLabel
                    label={t("ParkingZones.Payee")}
                    name="payee"
                    registerName="payee"
                    register={register}
                />
              </div>
              <div
                  className={cn(Style.container, Style["container"])}
              >
                <InputFieldWithLabel
                    label={t("ParkingZones.FinancialInstitution")}
                    name="financialInstitution"
                    registerName="financialInstitution"
                    register={register}
                />
              </div>
            </div>
          </div>

          <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "30px",
                marginLeft: "30px",
                justifyContent: "space-between",
              }}
          >
            <div style={{flex: 1}}>
              <TextareaWithLabel
                  label={t("GasStations.Others")}
                  name="others"
                  textAreaStyle={{
                    minHeight: "60px",
                    boxShadow: "none",
                    border: "1px solid #c7c7c7",
                  }}
                  registerName="others"
                  register={register}
              />
            </div>
          </div>

          <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "30px",
                marginLeft: "30px",
                justifyContent: "space-between",
              }}
          >
            <div style={{flex: 1}}>
              <InputFieldWithLabel
                  label={t("GasStations.RepresentedBy")}
                  name="representedBy"
                  registerName="representedBy"
                  register={register}
              />
            </div>
            <div style={{flex: 1, marginLeft: "40px"}}>
              <InputFieldWithLabel
                  label={t("GasStations.RelationToProperty")}
                  name="relationToProperty"
                  registerName="relationToProperty"
                  register={register}
              />
            </div>
          </div>

          <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "30px",
                marginLeft: "30px",
                justifyContent: "space-between",
              }}
          >
            <div style={{flex: 1}}>
              <TextareaWithLabel
                  label={t("GasStations.NotedBy")}
                  name="notedBy"
                  textAreaStyle={{
                    minHeight: "60px",
                    boxShadow: "none",
                    border: "1px solid #c7c7c7",
                  }}
                  registerName="notedBy"
                  register={register}
              />
            </div>
          </div>
          <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "30px",
                marginLeft: "30px",
                justifyContent: "space-between",
              }}
          >
            <div style={{flex: 1}}>
              <TextareaWithLabel
                  label={t("GasStations.InjuredPerson")}
                  name="injuredPerson"
                  textAreaStyle={{
                    minHeight: "60px",
                    boxShadow: "none",
                    border: "1px solid #c7c7c7",
                  }}
                  registerName="injuredPerson"
                  register={register}
              />
            </div>
          </div>
          <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "30px",
                marginLeft: "30px",
                justifyContent: "space-between",
              }}
          >
            <div style={{flex: 1}}>
              <TextareaWithLabel
                  label={t("GasStations.AdditionalInfo")}
                  name="additionalInfo"
                  textAreaStyle={{
                    minHeight: "60px",
                    boxShadow: "none",
                    border: "1px solid #c7c7c7",
                  }}
                  registerName="additionalInfo"
                  register={register}
              />
            </div>
          </div>

          <div className={Style.section}>
            <div className={Style.section__title}>
              {t("ParkingZones.documents")}
            </div>
            <div className={Style.container}>
              <>
                <label htmlFor="file-upload" className={Style.fileUpload}>
                  {t("GasStations.PowerOfAttorney")}
                </label>
                {powerOfAttorneyName !== "" && (
                    <label>
                      {powerOfAttorneyName === "" ? null : (
                          <div>
                            {showDocumentsNames(
                                powerOfAttorney,
                                setPowerOfAttorney,
                                powerOfAttorneyName,
                                setPowerOfAttorneyName
                            )}
                          </div>
                      )}
                    </label>
                )}

                <input
                    id="file-upload"
                    type="file"
                    accept="image/jpg, image/png, image/jpeg"
                    className="powerOfAttorney"
                    onChange={(e) => {
                      documentUpload(
                          e,
                          powerOfAttorney,
                          setPowerOfAttorney,
                          powerOfAttorneyName,
                          setPowerOfAttorneyName,
                          true
                      );
                    }}
                    multiple={false}
                />
                <label htmlFor="documentation" className={Style.fileUpload}>
                  {t("GasStations.GSDocumentation")}
                </label>
                {documentationName !== "" && (
                    <label>
                      {documentationName === "" ? null : (
                          <div>
                            {showDocumentsNames(
                                documentation,
                                setDocumentation,
                                documentationName,
                                setDocumentationName
                            )}
                          </div>
                      )}
                    </label>
                )}
                <input
                    id="documentation"
                    type="file"
                    accept=".pdf, .docx"
                    className="documentation"
                    multiple
                    onChange={(e) => {
                      documentUpload(
                          e,
                          documentation,
                          setDocumentation,
                          documentationName,
                          setDocumentationName,
                          false
                      );
                    }}
                />
              </>
            </div>
          </div>

          <div className={Style.section}>
            <div
                className={cn(
                    Style.section__title,
                    Style["section__title--admin"]
                )}
            >
              {t("ParkingZones.chooseAdmin")}
            </div>

            {isLoggedUserSysadmin ? (
                <Controller
                    name="keycloakAdminId"
                    control={control}
                    render={() => (
                        <Autocomplete
                            className={Style.textStyleSelectEditModal}
                            id={"keycloakAdminId"}
                            onChange={(e, value) => {
                              if (value) {
                                setGasStationAdmin(value.id);
                              }
                            }}
                            disableClearable
                            value={admins.find(x => x.id === keycloakAdminId) || null}
                            renderInput={(params) => <TextField {...params} placeholder={"auswählen"}/>}
                            options={admins}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(x) => `${x.firstName} ${x.lastName} | ${x.emailAddress} ${x.companyName ? "| " + x.companyName : ""}`}
                        />
                    )}
                />
            ) : (
                <div className={Style.text}>
                  {loggedUser?.firstName + " " + loggedUser?.lastName}
                </div>
            )}

            <div
                className={cn(
                    Style.section__title,
                    Style["section__title--admin"]
                )}
            >
              {"PZ Manager"}
            </div>

            {renderManagers()}

            <button
                type="button"
                className={
                  managersIds?.includes("0")
                      ? cn(
                          Style.addManagerButton,
                          Style["addManagerButton--disabled"]
                      )
                      : cn(
                          Style.addManagerButton,
                          Style["addManagerButton--enabled"]
                      )
                }
                onClick={() => {
                  setManagersIds([...managersIds, "0"]);
                  setManagersComboBoxNames([
                    ...managersComboBoxNames,
                    counter + 1,
                  ]);
                  setCounter(counter + 1);
                }}
                disabled={managersIds?.includes("0")}
            >
              {"+ Neue Manager" /*TODO translation*/}
            </button>

            <div className={Style.buttonContainer}>
              <Button
                  style={{
                    width: "25%",
                  }}
                  buttonStyle={{
                    width: "100%",
                  }}
                  disabled={isSubmitDisabled}
                  text={t("GasStations.saveGasStation")}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default AddGasStationModal;
